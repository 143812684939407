import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { API } from "../Constants/API";
import { Constants } from "../Constants/Constants";
import { ApiResponseCallback } from "../Interfaces/ApiResponseCallback";
import { RequestResponseModel } from "../Models/RequestResponseModel";
import { ShareCalculatedPremiumModel } from "../Models/ShareCalculatedPremiumModel";
import { PremiumParser } from "../parsers/PremiumParser";
import { StringFormatPipe } from "../Pipes/string-format/string-format.pipe";
import { ApiService } from "../services/apiService/api.service";
import { DataServiceService } from "../services/dataService/data-service.service";
import { CommonFunctions } from "../Utils/CommonFunctions";
import { Utilities } from "../Utils/Utilities";


@Injectable({
    providedIn: "root"
})

export class CallWebApi {
    public self;
    public request = "";
    // App Mode Array
    appMode: Array<string> = ["alfa", "dvlp", "beta", "live"];
    // Currently enable app mode index. We can change app mode on change of enableAppMode value.
    // 0 => alfa, 1 => dvlp, 2 => beta, 3 => live
    enableAppMode = 3;
    private readonly getEnableMode = this.appMode[this.enableAppMode];
    constructor(private apiService: ApiService,
        private stringFormat: StringFormatPipe,
        public parser: PremiumParser,
        public dataService: DataServiceService,
        private api: API,
        @Inject(LOCAL_STORAGE) private cookieService: WebStorageService,
        public constants: Constants,
        public commonFunctions: CommonFunctions,
        public utilities: Utilities) { }

    getSelectedStateData(statename: string, url: string, apiResponseCallback: ApiResponseCallback) {
        const self = this;
        this.apiService.hitGetApi(url, {
            onSuccess(success) {
                self.cookieService.set(url, JSON.stringify(success));
                self.cookieService.set(self.constants.selectedState, statename);
                apiResponseCallback.onSuccess(success);
            }, onError(errCode, errMsg) {
                hideShowLoader(false, self);
                apiResponseCallback.onError(errCode, errMsg);
            }
        });
    }

    callPremiumCalculateApi(paramsString: string, stateId: string, apiResponseCallback: ApiResponseCallback) {
        const self = this;
        setRequestToVariable(this, paramsString);
        hideShowLoader(true, this);
        try {
            const calculatedApi = self.stringFormat.transform(self.api.getBaseApiUrlJson(self.getEnableMode),  stateId, paramsString);
            self.hitPremiumApi(calculatedApi, stateId, apiResponseCallback);
        } catch (error) {
            hideShowLoader(false, self);
            apiResponseCallback.onError(error.errCode, error.errorMsg);
        }
    }

    callPdfGenerationApi(stateId: string, resultAttr: string, uiInfoAttr: string, pInputParam: string, email: string, download: string,
        apiResponseCallback: ApiResponseCallback) {
        const self = this;
        hideShowLoader(true, this);
        try {
            const generatePdfUrl = self.stringFormat.transform(self.api.getGeneratePdfUrlJson(self.getEnableMode),
                stateId, resultAttr, uiInfoAttr, pInputParam, email, download);
            self.hitPdfGenerationApi(generatePdfUrl, apiResponseCallback);
        } catch (error) {
            apiResponseCallback.onError(error.errCode, error.errorMsg);
        }
    }

    private hitPremiumApi(url, stateId, apiResponseCallback: ApiResponseCallback) {
        const self = this;

        this.apiService.hitGetApi(url, {
            onSuccess(success) {
                hideShowLoader(false, self);
                parseResponse(self, success, stateId, apiResponseCallback);
            }, onError(errCode, errorMsg) {
                hideShowLoader(false, self);
                apiResponseCallback.onError(errCode, errorMsg);
            }
        });
    }

    private hitPdfGenerationApi(url, apiResponseCallback: ApiResponseCallback) {
        const self = this;

        this.apiService.hitGetApi(url, {
            onSuccess(success) {
                hideShowLoader(false, self);
                parsePdfGenerationResponse(self, success, apiResponseCallback);
            }, onError(errCode, errorMsg) {
                hideShowLoader(false, self);
                apiResponseCallback.onError(errCode, errorMsg);
            }
        });
    }
}

function parseResponse(context: CallWebApi, response, stateId, apiResponseCallback: ApiResponseCallback) {
    hideShowLoader(false, context);
    const responseBody = response.Envelope.Body;
    if (responseBody.hasOwnProperty("Fault")) {
        context.commonFunctions.showAlertDialog(context.constants.alertErrorTitle, responseBody.Fault.message);
    } else {
        const parameters = responseBody.dataset[0].parameter;
        let result: string;
        if (parameters[0] && parameters[0].CalculatedPremium) {
            result = parameters[0].CalculatedPremium;
        } else {
            result = parameters[1].CalculatedPremium;
        }
        if (result) {
            const resultJson = context.utilities.convertResponseToJson(result);
            if (resultJson.hasOwnProperty("servererrmsg")) {
                apiResponseCallback.onError(0, resultJson.servererrmsg);
            } else if (resultJson.RatesCode >= 3000 && resultJson.RatesMsg !== "") {
                apiResponseCallback.onError(0, resultJson.RatesMsg);
            } else {
                createAndSendRequestResponseModel(context, context.utilities.convertRequestToGeneratePdfRequest(context.request), result,
                    stateId);
                const parsedResponse: ShareCalculatedPremiumModel = context.parser.parseResponse(resultJson, stateId);
                parsedResponse.createdRequestString = context.request;
                apiResponseCallback.onSuccess(parsedResponse);
            }
        } else {
            apiResponseCallback.onError(0, "Unable to process your request");
        }
    }
}

function parsePdfGenerationResponse(context: CallWebApi, response, apiResponseCallback: ApiResponseCallback) {
    const responseBody = response.Envelope.Body;
    if (responseBody.hasOwnProperty("Fault")) {

        apiResponseCallback.onError(1, responseBody.Fault.message);
    } else {
        const downloadPdf = responseBody.dataset[0].parameter[5].Downloadresponse;
        let pdfBase64 = "";
        if (downloadPdf !== "no") {
            const pdfArray = responseBody.dataset[1].pdf;
            pdfArray.forEach(element => {
                pdfBase64 = pdfBase64.concat(element.base64);
            });
            console.log(pdfBase64);
        }
        apiResponseCallback.onSuccess(pdfBase64);
    }
}

function hideShowLoader(showLoader: boolean, context: CallWebApi) {
    context.dataService.onHideShowLoader(showLoader);
}
function setRequestToVariable(context: CallWebApi, requestData: string) {
    context.request = requestData;
}

function createAndSendRequestResponseModel(context: CallWebApi, requestString: string, responseString: string, stateId: string) {
    const requestResponseModel: RequestResponseModel = new RequestResponseModel();
    requestResponseModel.request = requestString;
    requestResponseModel.response = responseString;
    requestResponseModel.stateId = stateId;

    context.dataService.setRequestResponse(requestResponseModel);
}
