import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { detect } from "detect-browser";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Subscription } from "rxjs";
import { CallWebApi } from "../../ApiCallInterface/CallWebApi";
import { API } from "../../Constants/API";
import { Constants } from "../../Constants/Constants";
import * as statePaths from "../../Constants/StatePaths";
import * as states from "../../Constants/States";
import * as browserVersions from "../../Constants/SupportedBrowserVersions";
import { ApiResponseCallback } from "../../Interfaces/ApiResponseCallback";
import { BasicConfigModel } from "../../Models/BasicConfigModel";
import { EndorsementModel } from "../../Models/EndorsementModel";
import { PropertyTypeModel } from "../../Models/PropertyTypeModel";
import { RegionModel } from "../../Models/RegionModel";
import { DataServiceService } from "../../services/dataService/data-service.service";
import { CommonFunctions } from "../../Utils/CommonFunctions";

@Component({
    selector: "app-home-view",
    templateUrl: "./home-view.component.html",
    styleUrls: ["./home-view.component.css"]
})
export class HomeViewComponent implements OnInit, OnDestroy, ApiResponseCallback {
    @Input()
    basicConfigJson: BasicConfigModel;
    public stateName = "Please Select State";
    public response: any;
    public propertyTypeArray: PropertyTypeModel[] = [];
    public ownerEndorsements: EndorsementModel[] = [];
    public loanEndorsements: EndorsementModel[] = [];
    public secondLoanEndorsements: Array<EndorsementModel> = new Array<EndorsementModel>();
    public regionArray: Array<RegionModel> = [];
    dataAvailable = 0;
    private pathToState = "";
    private data: any;
    loading = false;
    fromUrl = false;
    getStateDataApiCalled = false;
    activatedRouteSubscription: Subscription = null;
    loaderSubscription: Subscription = null;
    browserSupported = true;
    currentRegion = "";
    selectedRegionDescription = "";

    constructor(
        private commonFunctions: CommonFunctions,
        private api: API,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dataservice: DataServiceService,
        private constants: Constants,
        private location: Location,
        public changeDetectRef: ChangeDetectorRef,
        public dataService: DataServiceService,
        private callWebApi: CallWebApi,
        @Inject(LOCAL_STORAGE) private localStorage: WebStorageService
    ) { }
    ngOnInit() {

        this.registerOnUrlChange();
        registerHideShowLoaderBroadcast(this);
        const browser = detect();
        if (browser) {
            const versionNumber: number = browser.version.split(".")[0];
            checkSupportedVersion(this, versionNumber, browser.name);
        }
    }

    private registerOnUrlChange() {
        const previousSelectedState: string = this.localStorage.get(this.constants.selectedState);
        this.activatedRouteSubscription = this.activatedRoute.paramMap.subscribe(params => {
            const state = this.activatedRoute.snapshot.url.join("");
            if (state) {
                this.onStateByUrlChanged(state);
            } else if (previousSelectedState) {
                this.onstateSelected(previousSelectedState);
            }
        });
    }

    private onStateByUrlChanged(state: string) {
        if (state) {
            this.fromUrl = true;
            this.stateName = state.replace(/ /g, "");
            this.getDataOnStateSelection();
        }
    }

    onSuccess(result: any) {
        this.response = result.RCUI;
        if (!this.response) {
            this.response = result.georgiaUI;
        }
        this.dataAvailable = 1;
        if (!this.fromUrl) {
            this.navigateToPath();
        }

        this.checkAndSendSelectedRegion();
        this.checkAndSendpropertyType();
        this.checkAndSendEndorsements();
    }
    onError(errorCode: number, errorMsg: string) { }
    private checkAndSendEndorsements() {
        if (this.response.hasOwnProperty("endorsement")) {
            this.createCategorywiseEndorsementsArray(this.response.endorsement);

            let showProposedRateFlag = false;
            if (this.response.proposedRateConfig) {
                showProposedRateFlag = this.response.proposedRateConfig[0].showProposedRateFlag;
            }

            this.data = {
                ownerEndorsements: this.ownerEndorsements,
                loanEndorsements: this.loanEndorsements,
                secondLoanEndorsements: this.secondLoanEndorsements,
                showPropsedRateFlag: showProposedRateFlag
            };
            this.dataservice.onEndorsementDataChanged(JSON.stringify(this.data));
        }
    }
    private checkAndSendpropertyType() {
        if (this.response.hasOwnProperty("propertyType")) {
            this.createPropertyRateTypeArray(this.response.propertyType);
            broadcastRateTypeOnPropertyTypeChanged(
                this.dataservice,
                this.propertyTypeArray[0]
            );
        }
    }

    private checkAndSendSelectedRegion() {
        if (this.response.hasOwnProperty("region")) {
            this.regionArray = this.response.region;
            const region: RegionModel = this.regionArray[0];
            createRegionDesc(this, region);
            this.dataservice.sendAllRegionsToReasonsModel(this.regionArray);
            this.dataservice.shareSelectedRegion(this.regionArray[0].regionCode);
        }
    }

    getSelectedStateData(api: any) {
        if (!this.getStateDataApiCalled) {
            this.getStateDataApiCalled = true;
            this.callWebApi.getSelectedStateData(this.stateName, api, this);
            setTimeout(() => {
                this.getStateDataApiCalled = false;
            }, 1000);
        }
    }

    createPropertyRateTypeArray(propertyType) {
        this.propertyTypeArray = propertyType;
    }

    createCategorywiseEndorsementsArray(endorsements) {
        this.ownerEndorsements = [];
        this.loanEndorsements = [];
        this.secondLoanEndorsements = [];
        endorsements.forEach(element => {
            const endorsementFor = element.endorsementType;
            this.changeDetectRef.markForCheck();
            element.checked = false;
            element.visible = false;
            switch (endorsementFor) {
                case "":
                    this.ownerEndorsements.push(element);
                    this.loanEndorsements.push(element);
                    this.secondLoanEndorsements.push(element);
                    break;
                case "L":
                    this.loanEndorsements.push(element);
                    this.secondLoanEndorsements.push(element);
                    break;
                case "O":
                    this.ownerEndorsements.push(element);
                    break;
            }
        });
    }

    onRegionChange(index) {
        this.dataService.onResetAllFieldsClick();
        this.currentRegion = this.regionArray[index].regionCode;
        createRegionDesc(this, this.regionArray[index]);
        this.dataservice.shareSelectedRegion(this.currentRegion);
    }

    onPropertyTypeChange(index) {
        broadcastRateTypeOnPropertyTypeChanged(
            this.dataservice,
            this.propertyTypeArray[index]
        );
        this.dataservice.onEndorsementDataChanged(JSON.stringify(this.data));
        if (this.currentRegion) {
            this.dataservice.shareSelectedRegion(this.currentRegion);
        }

    }

    onstateSelected(selectedState) {
        this.stateName = selectedState.replace(/ /g, "");
        this.fromUrl = false;
        this.getDataOnStateSelection();
    }

    getDataOnStateSelection() {
        this.currentRegion = "";
        // this.dataService.onResetAllFieldsClick();
        this.stateName = states.stateArray[this.stateName.toLowerCase()];
        this.dataservice.onStateSelected(this.stateName);
        switch (this.stateName) {
            case states.ALABAMA:
                this.pathToState = statePaths.PATH_TO_ALABAMA;
                this.getSelectedStateData(this.api.albamaV3);
                break;
            case states.ALABAMA:
                this.pathToState = statePaths.PATH_TO_AL;
                this.getSelectedStateData(this.api.albamaV3);
                break;
            case states.GEORGIA:
                this.pathToState = statePaths.PATH_TO_GEORGIA;
                this.getSelectedStateData(this.api.georgiaV2);
                break;
            case states.GEORGIA:
                this.pathToState = statePaths.PATH_TO_GE;
                this.getSelectedStateData(this.api.georgia);
                break;
            case states.FLORIDA:
                this.pathToState = statePaths.PATH_TO_FLORIDA;
                this.getSelectedStateData(this.api.florida);
                break;
            case states.MISSOURI:
                this.pathToState = statePaths.PATH_TO_MISSOURI;
                this.getSelectedStateData(this.api.missouri);
                break;
            case states.MISSISSIPPI:
                this.pathToState = statePaths.PATH_TO_MISSISSIPPI;
                this.getSelectedStateData(this.api.mississippi);
                break;
            case states.SOUTH_CAROLINA:
                this.pathToState = statePaths.PATH_TO_SOUTH_CAROLINA;
                this.getSelectedStateData(this.api.southCarolina);
                break;
            case states.MINNESOTA:
                this.pathToState = statePaths.PATH_TO_MINNESOTA;
                this.getSelectedStateData(this.api.minnesota);
                break;
            case states.COLORADO:
                this.pathToState = statePaths.PATH_TO_COLORADO;
                this.getSelectedStateData(this.api.colorado);
                break;
            case states.KANSAS:
                this.pathToState = statePaths.PATH_TO_KANSAS;
                this.getSelectedStateData(this.api.kansas);
                break;
            case states.TEXAS:
                this.pathToState = statePaths.PATH_TO_TEXAS;
                this.getSelectedStateData(this.api.texas);
                break;
            case states.WISCONSIN:
                this.pathToState = statePaths.PATH_TO_WISCONSIN;
                this.getSelectedStateData(this.api.wisconsin);
                break;
            case states.UTAH:
                this.pathToState = statePaths.PATH_TO_UTAH;
                this.getSelectedStateData(this.api.utah);
                break;
            case states.NEVADA:
                this.pathToState = statePaths.PATH_TO_NEVADA;
                this.getSelectedStateData(this.api.nevada);
                break;
            case states.NEWMEXICO:
                this.pathToState = statePaths.PATH_TO_NEWMEXICO;
                this.getSelectedStateData(this.api.newmexicoV3);
                break;
            default:
                this.pathToState = statePaths.PATH_TO_NOT_FOUND;
                this.navigateToPath();
                break;
        }
    }
    navigateToPath() {
        if (this.pathToState) {
            this.location.go(this.pathToState);
            this.router.navigate([this.pathToState]);
        }
    }

    getVisibleNameOfState(stateName: string) {
        return states.getVisibleNameStateArray[stateName.toLowerCase()];
    }

    getPropertTypeClass() {
        if (this.basicConfigJson && this.basicConfigJson.regionAvailable) {
            return "col-md-4";
        } else {
            return "col-md-8";
        }
    }


    getDisplayFormattedState() {
        return this.stateName.split(/(?=[A-Z])/).join(" ");
    }

    ngOnDestroy(): void {
        if (
            this.activatedRouteSubscription &&
            !this.activatedRouteSubscription.closed
        ) {
            this.activatedRouteSubscription.unsubscribe();
        }
        if (this.loaderSubscription && !this.loaderSubscription.closed) {
            this.loaderSubscription.unsubscribe();
        }
    }
}

function broadcastRateTypeOnPropertyTypeChanged(
    dataService: DataServiceService,
    propertRateType
) {
    dataService.onResetAllFieldsClick();
    dataService.onRateTypeDataChanged(JSON.stringify(propertRateType));
}
function registerHideShowLoaderBroadcast(context: HomeViewComponent) {
    context.loaderSubscription = context.dataService.hideShowLoaderObservable.subscribe(
        showLoader => {
            context.loading = showLoader;
        }
    );
}

function checkSupportedVersion(context: HomeViewComponent, browserVersion: number, browserName: string) {
    switch (browserName) {
        case browserVersions.BROWSER_CHROME:
            context.browserSupported = browserVersions.MIN_SUPPORT_CHROME <= browserVersion;
            break;
        case browserVersions.BROWSER_FIREFOX:
            context.browserSupported = browserVersions.MIN_SUPPORT_FIREFOX <= browserVersion;
            break;
        case browserVersions.BROWSER_INTERNET_EXPLORER:
            context.browserSupported = browserVersions.MIN_SUPPORT_INTERNET_EXPLORER <= browserVersion;
            break;
        case browserVersions.BROWSER_EDGE:
            context.browserSupported = browserVersions.MIN_SUPPORT_EDGE <= browserVersion;
            break;
        case browserVersions.IOS:
            context.browserSupported = browserVersions.MIN_SUPPORT_IOS <= browserVersion;
            break;
        default:

            context.browserSupported = false;
            break;
    }
}

function createRegionDesc(context: HomeViewComponent, region: RegionModel) {
    context.selectedRegionDescription = region.regionCode + " (" + region.description + ")";
    context.changeDetectRef.markForCheck();


}
